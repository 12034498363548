import { Routes } from '@angular/router';
import { AuthGuard, RedirectFromLoginPageGuard } from '@auth/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import {
  rbacGuard,
  rbacSomeGuard,
} from '@shared/role-bace-acces-controller/rbac.guard';
import { RESOURCE_PERMISSION } from '@shared/role-bace-acces-controller/resource-permission.constant';
import { permissionsResolver } from '@core/resolvers/permissions.resolver';
import { academicYearResolver } from '@core/resolvers/academic-year.resolver';
import { StudentsScopeResolver } from '@core/resolvers/students-scope.resolver';
import { MobileMenuGuard } from '@core/guards/mobile-menu.guard';
import { UnauthorizedPage } from '@pages/unauthorized/unauthorized.page';
import { chatGuard } from '@pages/chat/chat.guard';
import { redirectIfSchoolStructureEmptyGuard } from '@shared/guards/redirect-if-school-structure-empty.guard';

export const routes: Routes = [
  {
    path: 'pages/support',
    title: 'Support',
    loadComponent: () =>
      import('./pages/support/support.component').then(
        (p) => p.SupportComponent,
      ),
  },
  {
    path: 'pages/terms-and-conditions',
    title: 'Terms & Conditions',
    loadComponent: () =>
      import('./pages/terms-conditions/terms-conditions.component').then(
        (p) => p.TermsConditionsComponent,
      ),
  },
  {
    path: 'pages/privacy-policy',
    title: 'Privacy policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (p) => p.PrivacyPolicyComponent,
      ),
  },
  {
    path: 'login',
    canActivate: [RedirectFromLoginPageGuard],
    loadChildren: () =>
      import('./pages/login/login.routes').then((mod) => mod.authRoutes),
  },
  // Authenticated users only
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      Permissions: permissionsResolver,
      academicYears: academicYearResolver,
      guardianStudents: StudentsScopeResolver,
    },
    canActivate: [AuthGuard, redirectIfSchoolStructureEmptyGuard],
    data: { breadcrumb: 'global.home.title' },
    children: [
      {
        path: 'home',
        data: { pageTitle: 'global.home.title' },
        loadComponent: () =>
          import(
            './pages/announcements/pages/view-posts/view-posts.component'
          ).then((m) => m.ViewPostsComponent),
      },
      {
        path: 'user-management',
        data: {
          breadcrumb: 'global.user_management.title',
          pageTitle: 'global.user_management.title',
        },
        loadChildren: () =>
          import('./pages/user-management/user-management.routes').then(
            (m) => m.UserManagementRoutes,
          ),
      },
      {
        path: 'roles',
        data: {
          breadcrumb: 'roles_permissions.roles_and_permissions.title',
          pageTitle: 'roles_permissions.roles_and_permissions.title',
        },
        loadChildren: () =>
          import('./pages/role-management/role-management.routes').then(
            (m) => m.RoleManagmentRoutes,
          ),
        canActivate: [
          rbacGuard(RESOURCE_PERMISSION.rolesAndPermission.viewRoleListing),
        ],
      },
      {
        path: 'school-structure',
        data: {
          breadcrumb: 'global.school_structure.title',
          pageTitle: 'global.school_structure.title',
        },
        canActivate: [rbacGuard(RESOURCE_PERMISSION.company.viewCompanyList)],
        loadChildren: () =>
          import('./pages/school-structure/school-structure.routes').then(
            (m) => m.SchoolStructureRoutes,
          ),
      },
      {
        path: 'academic-years',
        data: {
          breadcrumb: 'global.academic_enrolment.title',
          pageTitle: 'global.academic_enrolment.title',
        },
        canActivate: [
          rbacGuard(RESOURCE_PERMISSION.academicYear.academicYearListView),
        ],
        loadChildren: () =>
          import('./pages/academic-year/academic-year.routes').then(
            (m) => m.AcademicRoutes,
          ),
      },
      {
        path: 'course-management',
        data: {
          breadcrumb: 'global.course_management.title',
          pageTitle: 'global.course_management.title',
        },
        loadChildren: () =>
          import('./pages/course-management/course-management.routes').then(
            (m) => m.CourseManagementRoutes,
          ),
      },
      {
        path: 'my-courses',
        data: {
          breadcrumb: 'global.my_courses.title',
          pageTitle: 'global.my_courses.title',
        },
        loadChildren: () =>
          import('./pages/course-management/my-courses.routes').then(
            (m) => m.MyCoursesRoutes,
          ),
      },
      {
        path: 'announcements',
        data: {
          breadcrumb: 'global.announcements.title',
          pageTitle: 'global.announcements.title',
        },
        canActivate: [
          rbacGuard(RESOURCE_PERMISSION.announcement.announcementListView),
        ],
        loadChildren: () =>
          import('./pages/announcements/announcements.routes').then(
            (m) => m.AnnouncementsRoutes,
          ),
      },
      {
        path: 'support-tickets',
        data: {
          breadcrumb: 'global.support_ticket.title',
          pageTitle: 'global.support_ticket.title',
        },
        canActivate: [
          rbacGuard(RESOURCE_PERMISSION.supportTicket.viewMyAssignedTickets),
        ],
        loadChildren: () =>
          import('./pages/support-tickets/support-tickets.routes').then(
            (m) => m.SupportTicketsRoutes,
          ),
      },
      {
        path: 'user-feed',
        data: {
          breadcrumb: 'global.announcements.title',
          pageTitle: 'global.announcements.title',
        },
        loadComponent: () =>
          import(
            './pages/announcements/pages/view-posts/view-posts.component'
          ).then((m) => m.ViewPostsComponent),
      },
      {
        path: 'chat',
        data: {
          pageTitle: 'global.chats.title',
        },
        canActivate: [
          rbacGuard(RESOURCE_PERMISSION.chat.chatListView),
          chatGuard(),
        ],
        loadComponent: () =>
          import('./pages/chat/chat.page').then((m) => m.ChatPage),
      },
      {
        path: 'settings',
        data: {
          breadcrumb: 'global.settings.title',
          pageTitle: 'global.settings.title',
        },
        loadChildren: () =>
          import('./pages/settings/settings.routes').then(
            (m) => m.SettingsRoutes,
          ),
      },
      {
        path: 'help-center',
        data: {
          breadcrumb: 'global.help_center.title',
          pageTitle: 'global.help_center.title',
        },
        loadChildren: () =>
          import('./pages/help-center/help-center.routes').then(
            (m) => m.HelpCenterRoutes,
          ),
      },
      {
        path: 'attendance',
        data: {
          breadcrumb: 'global.attendance.title',
          pageTitle: 'global.attendance.title',
        },
        loadChildren: () =>
          import('./pages/attendance/attendance.routes').then(
            (m) => m.AttendanceRoutes,
          ),
        canActivate: [
          rbacSomeGuard([
            RESOURCE_PERMISSION.attendance.LIST,
            RESOURCE_PERMISSION.attendance.DETAIL,
            RESOURCE_PERMISSION.attendance.CREATE,
            RESOURCE_PERMISSION.attendance.UPDATE,
            RESOURCE_PERMISSION.attendance.CONFIRM_ABSENCE,
          ]),
        ],
      },
      {
        path: 'reports',
        loadComponent: () =>
          import('./pages/reports/reports.page').then((m) => m.ReportsPage),
        canActivate: [
          rbacSomeGuard([...Object.values(RESOURCE_PERMISSION.REPORTS.READ)]),
        ],
      },
      {
        path: 'menu',
        canActivate: [MobileMenuGuard],
        loadComponent: () =>
          import('./pages/mobile-menu/mobile-menu.page').then(
            (m) => m.MobileMenuPage,
          ),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      { path: 'unauthorized', component: UnauthorizedPage },
      {
        path: '404',
        loadComponent: () =>
          import('./pages/page-not-found/page-not-found.page').then(
            (m) => m.PageNotFoundPage,
          ),
      },
      { path: '**', redirectTo: '/404', pathMatch: 'full' },
    ],
  },
];
