import {
  Component,
  Inject,
  OnInit,
  inject,
  ErrorHandler,
  APP_INITIALIZER,
  signal,
} from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { DOCUMENT, NgClass } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from '@ui-kit/hes-breadcrumbs/breadcrumb.service';
import { LANGUAGE_LOCAL_STORAGE_KEY } from '@shared/constants/localstorage-keys.constants';
import { Router } from '@angular/router';
import { SplashScreenWebComponent } from '@layout/components/splash-screen-web/splash-screen-web.component';
import { LayoutService } from '@layout/layout.service';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { TuiDialogModule, TuiRootModule } from '@taiga-ui/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,
    SplashScreenWebComponent,
    NgClass,
    TuiRootModule,
    TuiDialogModule,
  ],
  providers:
    environment.ENVIRONMENT_NAME !== 'local'
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
        ]
      : [],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly layout = inject(LayoutService);
  private readonly ngUnsubscribe$ = new Subject<void>();

  showSplashScreen = signal(true);

  constructor(
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    _service: BreadcrumbService,
  ) {
    toObservable(this.layout.showSplashScreen)
      .pipe(takeUntilDestroyed(), debounceTime(4000))
      .subscribe((v) => {
        this.showSplashScreen.set(v);
      });
  }

  ngOnInit(): void {
    const lang = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) ?? 'ar';
    this.translocoService.setActiveLang(lang);
    this.document.documentElement.lang = lang;
    this.document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
