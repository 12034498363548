import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  IonRouterOutlet,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonFooter,
  IonImg,
  IonProgressBar,
  IonBackdrop,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonButton,
} from '@ionic/angular/standalone';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { faArrowRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { AuthService } from '@auth/auth.service';
import { HeaderUserInfoComponent } from '@shared/components/header-user-info/header-user-info.component';
import { CommonModule, Location } from '@angular/common';
import { hesIcon } from '@shared/types';
import { SchoolStructureNavItemComponent } from './components/school-structure-nav-item/school-structure-nav-item.component';
import { filter, map } from 'rxjs';
import { SchoolStructureNavButtonComponent } from './components/school-structure-nav-button/school-structure-nav-button.component';
import { isMobile, isRtl } from '@shared/utils/platform';
import { SchoolStructureNavMenuComponent } from './components/school-structure-nav-menu/school-structure-nav-menu.component';
import { faComments } from '@fortawesome/pro-duotone-svg-icons';
import { RoleBaseAccessControlService } from '@shared/role-bace-acces-controller/role-base-access-control.service';
import { BreadcrumbModule } from '@ui-kit/hes-breadcrumbs/breadcrumb.module';
import { SchoolScopeSelectionPopoverComponent } from './components/school-scope-selection-popover/school-scope-selection-popover.component';
import { SchoolScopeSelectionButtonComponent } from './components/school-scope-selection-button/school-scope-selection-button.component';
import { AcademicYearSelectionComponent } from './components/academic-year-selection/academic-year-selection.component';
import { AcademicYearSelectionPopoverComponent } from './components/academic-year-selection-popover/academic-year-selection-popover.component';
import { LayoutService } from './layout.service';
import { SchoolStructureEntityType } from '@ui-kit/hes-school-structure-control/school-structure-control-item.interface';
import { StructureDepth } from '@shared/utils/school-structure';
import { AcademicYearsScopeService } from '@core/academic-years-scope.service';
import { AnnouncementService } from '@pages/announcements/data-access/announcement.service';
import { ChatMessageListenerService } from '@pages/chat/utils/chat-message-listener.service';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { HesSubscription } from '@shared/utils/hes-subscription.util';
import { TemplateProjectionService } from '@shared/services/template-projection.service';
import { Observable } from 'rxjs';
import { StudentSelectionComponent } from './components/student-selection/student-selection.component';
import { SchoolStructureScopeService } from '@core/school-structure-scope.service';
import { StudentSelectionScopeService } from '@core/student-selection-scope.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { LayoutUiControlService } from '@shared/services/layout-ui-control.service';
import { PageTitleService } from './page-title.service';
import { ClassLevelBadgesComponent } from '@pages/course-management/components/class-level-badges/class-level-badges.component';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { HesSearchableSelectComponent } from '../ui-kit/hes-searchable-select/hes-searchable-select.component';
import { faAnglesRight } from '@fortawesome/pro-solid-svg-icons';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  saxBook1Outline,
  saxBuildings2Outline,
  saxElement3Outline,
  saxHome2Outline,
  saxInfoCircleOutline,
  saxLogout1Outline,
  saxMessages3Outline,
  saxPeopleOutline,
  saxSetting2Outline,
  saxTicketOutline,
  saxUserOctagonOutline,
  saxUserSearchOutline,
  saxUserOutline,
  saxArrowLeft2Outline,
  saxDocumentCopyOutline,
} from '@ng-icons/iconsax/outline';

import {
  saxBook1Bold,
  saxElement3Bold,
  saxHome2Bold,
  saxMessages3Bold,
} from '@ng-icons/iconsax/bold';
import { settingPermissions } from '@pages/settings/settings.page';
import { RbacSomeDirective } from '@shared/role-bace-acces-controller/rbac.directive';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { UnreadNotificationService } from '@shared/services/unread-notification.service';
import { LANGUAGE_LOCAL_STORAGE_KEY } from '@shared/constants/localstorage-keys.constants';
import { mainMenuRoutes } from './menu-routes';
import { ChatService } from '@pages/chat/chat.service';
import { IMenuRoutes } from './menu-routes.interface';

interface UnreadCount {
  users: { [key: string]: number };
  groups: { [key: string]: number };
}

// Base type for all school structure items
export interface BaseSchoolStructureItem {
  id: number;
  name: string;
  hesIcon: hesIcon;
  hasAccess: boolean;
  path: string;
  parentId?: number | null;
  depth: StructureDepth;
}

// Specific type for level items
interface LevelSchoolStructureItem extends BaseSchoolStructureItem {
  type: 'level';
  schoolLevelId: number;
}

// Type for all other school structure entity types
interface OtherSchoolStructureItem extends BaseSchoolStructureItem {
  type: Exclude<SchoolStructureEntityType, 'level'>;
}

// Union type for side menu items
export type sideMenuSchoolStructureItem = (
  | LevelSchoolStructureItem
  | OtherSchoolStructureItem
) & {
  children: sideMenuSchoolStructureItem[];
};

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    IonButton,
    IonTabButton,
    IonTabBar,
    IonSpinner,
    IonBackdrop,
    IonProgressBar,
    IonImg,
    IonRouterOutlet,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonFooter,
    RouterModule,
    FontAwesomeModule,
    TranslocoDirective,
    HeaderUserInfoComponent,
    CommonModule,
    SchoolStructureNavItemComponent,
    SchoolStructureNavButtonComponent,
    SchoolStructureNavMenuComponent,
    BreadcrumbModule,
    SchoolScopeSelectionPopoverComponent,
    SchoolScopeSelectionButtonComponent,
    AcademicYearSelectionComponent,
    AcademicYearSelectionPopoverComponent,
    StudentSelectionComponent,
    ClassLevelBadgesComponent,
    HesSearchableSelectComponent,
    NgIconComponent,
    RbacSomeDirective,
    MenuItemComponent,
  ],
  viewProviders: [
    provideIcons({
      saxHome2Outline,
      saxBuildings2Outline,
      saxUserSearchOutline,
      saxPeopleOutline,
      saxBook1Outline,
      saxMessages3Outline,
      saxUserOctagonOutline,
      saxSetting2Outline,
      saxInfoCircleOutline,
      saxTicketOutline,
      saxLogout1Outline,
      saxHome2Bold,
      saxMessages3Bold,
      saxBook1Bold,
      saxElement3Bold,
      saxElement3Outline,
      saxUserOutline,
      saxArrowLeft2Outline,
      saxDocumentCopyOutline,
    }),
  ],
})
export class LayoutComponent implements OnInit, OnDestroy {
  faChat = faComments;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faAnglesRight = faAnglesRight;
  faAnglesLeft = faAnglesLeft;
  faCircleWarning = faCircleExclamation;
  isMobile = isMobile();
  isRtl = isRtl();

  faArrowRightFromBracket = faArrowRightFromBracket;
  isHideFeature = false;
  auth = inject(AuthService);
  private readonly router = inject(Router);

  layoutService = inject(LayoutService);
  private readonly rbacService = inject(RoleBaseAccessControlService);
  private readonly academicYearScopeService = inject(AcademicYearsScopeService);
  private readonly schoolStructureScopeService = inject(
    SchoolStructureScopeService,
  );
  private readonly studentSelectionScopeService = inject(
    StudentSelectionScopeService,
  );
  private readonly transloco = inject(TranslocoService);
  private readonly announcementService = inject(AnnouncementService);
  private readonly chatListenerService = inject(ChatMessageListenerService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly layoutUiControlService = inject(LayoutUiControlService);
  private readonly templateProjectionService = inject(
    TemplateProjectionService,
  );
  private readonly pageTitleService = inject(PageTitleService);
  private readonly locationService = inject(Location);
  private readonly chatService = inject(ChatService);
  private readonly unreadNotificationService = inject(
    UnreadNotificationService,
  );
  private history: string[] = [];

  currentPageTitle = this.pageTitleService.currentPageTitle;

  isShowProgressBar = this.layoutService.isShowProgressBar;

  projectedTemplateRef$: Observable<TemplateRef<any> | null> =
    this.templateProjectionService.templateRef$;

  currentClassName = this.layoutService.currentClassName;
  currentLevelName = this.layoutService.currentLevelName;
  isShowClassLevelBadges = signal(false);

  private readonly subscription = new HesSubscription();
  unReadCount = signal(0);
  public postAnnouncementsUnreadCounts = computed(() => {
    return (
      this.unreadNotificationService.unReadNotificationsCount()?.announcements
        ?.unreadCounts ?? 0
    );
  });

  currentPage = signal(this.router.url);
  showSchoolStructureSideMenu = signal(false);
  showMainMobileHeader = signal(false);
  isOffline = signal(false);

  isSideNavCollapsed = this.layoutService.isSideNavCollapsed;

  private academicYearId?: number =
    this.academicYearScopeService.selectedAcademicYear()?.id;

  selectedLanguage = this.transloco.getActiveLang();

  isGuardianUser = computed(() => {
    return this.auth.user()?.type === 'GUARDIAN';
  });

  coursesRoute = computed(() => {
    return this.auth.user()?.type === 'GUARDIAN' ||
      this.auth.user()?.type === 'STUDENT'
      ? 'my-courses'
      : 'course-management';
  });
  private mainMenu = mainMenuRoutes();
  readonly menuRoutes = computed(() => {
    const isSchoolStructureEmpty = this.isSchoolStructureEmpty();
    let filterMenu: IMenuRoutes[] = [];
    if (isSchoolStructureEmpty) {
      filterMenu = this.mainMenu()
        .filter((item) => item.isPublic === true)
        .map((item) => ({ ...item, placement: 'upper' }));
    } else {
      filterMenu = this.mainMenu()
        .filter((item) =>
          item.UserTypes
            ? item.UserTypes?.some(
                (userType) => userType === this.auth.user()?.type,
              )
            : true,
        )
        .filter((item) =>
          !!item.permissions
            ? this.rbacService.hasSomePermission(item.permissions)
            : true,
        )
        .filter((item) => (this.isHideFeature ? item.isHide !== true : item));
    }
    return {
      upper: filterMenu.filter((item) => item.placement == 'upper'),
      lower: filterMenu.filter((item) => item.placement === 'lower'),
    };
  });

  // layout control attribute
  isBreadcrumbVisible$ = this.layoutUiControlService.isBreadcrumbVisible$;

  isShowPageSpinner = this.layoutService.isShowPageSpinner;

  isShowBackButton = computed(() => {
    return !!(this.locationService.getState() as any)?.navigationId;
  });

  isWebBackBtn = this.layoutUiControlService.isShowWebBackBtn;

  settingMenuPermission: number[] = settingPermissions();

  readonly breadCrumbRestrictRoutes$ = this.router.events.pipe(
    map(() => {
      return this.router.url === '/home';
    }),
  );
  isSchoolStructureEmpty =
    this.schoolStructureScopeService.isSchoolStructureEmpty;
  constructor() {
    effect(() => {
      this.academicYearId =
        this.academicYearScopeService.selectedAcademicYear()?.id;
      if (!this.isSchoolStructureEmpty()) {
        this.unreadNotificationService.loadUnreadForSelectedAcademicYear();
      }
    });
    toObservable(this.studentSelectionScopeService.selectedStudent).subscribe(
      (student) => {
        if (student) {
          if (student.school?.id) {
            this.schoolStructureScopeService.updateSelectedStructure({
              id: student.school.id,
              type: 'school',
            } as sideMenuSchoolStructureItem);
          }
          if (student.academicYear?.id) {
            this.academicYearScopeService.updateSelectedAcademicYearbyId(
              student.academicYear?.id,
            );
            const activesemester =
              this.academicYearScopeService.findCurrentOrNearestAcademicYearOrSemester(
                this.academicYearScopeService.selectedAcademicYear()
                  ?.semesters ?? [],
              );
            if (activesemester)
              this.academicYearScopeService.updateSelectedSemester(
                activesemester,
              );
          }
        }
      },
    );
  }

  canGoBack(): boolean {
    return this.history.length > 1;
  }

  goBack(): void {
    if (this.canGoBack()) {
      // Remove the current URL
      this.history.pop();
      const previousUrl = this.history.pop();
      if (previousUrl) {
        this.router.navigateByUrl(previousUrl);
      }
    }
  }

  chatTrigger() {
    this.router.navigate(['/chat']);
  }

  ngOnInit(): void {
    if (this.router.url.split('/').find((url) => url === 'school-structure')) {
      this.showSchoolStructureSideMenu.set(true);
    } else {
      this.showSchoolStructureSideMenu.set(false);
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.history.push(event.urlAfterRedirects);
        const isSchoolStructure = event.url
          ?.split('/')
          .find((url: string) => url === 'school-structure');

        const isLMSContentManagement = event.url
          ?.split('/')
          .find((url: string) => url === 'my-courses');

        const isCMSContentManagement = event.url
          ?.split('/')
          .find((url: string) => url === 'course-management');

        const isContentManagementTopic = event.url
          ?.split('/')
          .find((url: string) => url === 'topics');

        const isHomeOrMenuPage = event.url
          ?.split('/')
          .find(
            (url: string) => url === 'home' || url === 'menu' || url === '404',
          );

        this.showMainMobileHeader.set(!!isHomeOrMenuPage);

        if (
          isLMSContentManagement ||
          (isCMSContentManagement && isContentManagementTopic)
        ) {
          this.isShowClassLevelBadges.set(true);
        } else this.isShowClassLevelBadges.set(false);

        if (isSchoolStructure) {
          this.showSchoolStructureSideMenu.set(true);
        } else {
          this.showSchoolStructureSideMenu.set(false);
        }
      });
    if (!this.isSchoolStructureEmpty()) {
      this.unreadNotificationService.loadUnreadForSelectedAcademicYear();
      if (this.chatService.isChatLoggedIn()) {
        this.chatListenerService.initListener();
        this.fetchUnreadCount();
        this.subscription.add =
          this.chatListenerService.fetchUnreadCount$.subscribe(() => {
            this.fetchUnreadCount();
          });
      }
    }
  }

  logout() {
    this.auth.logout();
  }

  onLanguageChange(event: any) {
    // after reload app component will set lang from local storage on app init
    localStorage.setItem(
      LANGUAGE_LOCAL_STORAGE_KEY,
      event?.detail?.value ?? event,
    );
    window.location.reload();
  }

  private async fetchUnreadCount() {
    const countResponse = await CometChat.getUnreadMessageCount();
    const totalUnreadCount = this.getTotalUnreadCount(
      countResponse as UnreadCount,
    );
    this.unReadCount.set(totalUnreadCount);
    this.cd.detectChanges();
  }

  private getTotalUnreadCount(response: UnreadCount): number {
    let totalUnreadCount = 0;

    for (const key in response.users) {
      if (response.users.hasOwnProperty(key)) {
        totalUnreadCount += response.users[key];
      }
    }

    for (const key in response.groups) {
      if (response.groups.hasOwnProperty(key)) {
        totalUnreadCount += response.groups[key];
      }
    }

    return totalUnreadCount;
  }
  isActive(route: string): boolean {
    return this.router.url.includes(route);
  }
  toggleSideNav() {
    this.layoutService.toggleSideNav();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  @HostListener('window:offline')
  setNetworkOffline(): void {
    this.isOffline.set(true);
  }

  @HostListener('window:online')
  setNetworkOnline(): void {
    this.isOffline.set(false);
  }
}
