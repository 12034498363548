import { TuiErrorModule, TuiRootModule } from '@taiga-ui/core';
import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
  inject,
  isDevMode,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
  Platform,
} from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AuthHeadersInterceptor,
  AuthTokenInterceptor,
  RefreshTokenInterceptor,
} from '@auth/auth.interceptor';
import { LanguageMiddlewareInterceptor } from '@core/interceptors/language-middleware.interceptor';
import { RoleBaseAccessControlService } from '@shared/role-bace-acces-controller/role-base-access-control.service';
import { BreadcrumbModule } from '@ui-kit/hes-breadcrumbs/breadcrumb.module';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { HesToastrComponent } from '@ui-kit/hes-alert/hes-alert-toast.component';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { firstValueFrom, map, of } from 'rxjs';
import { AuthService } from '@auth/auth.service';
import { StudentsService } from '@pages/user-management/students/students.service';
import { StudentSelectionScopeService } from '@core/student-selection-scope.service';
import { AcademicYearsScopeService } from '@core/academic-years-scope.service';
import { SchoolStructureScopeService } from '@core/school-structure-scope.service';
import { GuardianService } from '@pages/user-management/guardians/guardians.service';
import { sideMenuSchoolStructureItem } from '@layout/layout.component';
import { AcademicYearItem } from '@shared/interfaces/academic-year-scope.interface';
import * as Sentry from '@sentry/angular';
import { provideNgIconLoader } from '@ng-icons/core';
import { TUI_DATE_FORMAT } from '@taiga-ui/cdk';
import {
  TUI_CANCEL_WORD,
  TUI_DONE_WORD,
  tuiInputTimeOptionsProvider,
} from '@taiga-ui/kit';

if (environment.ENVIRONMENT_NAME !== 'local') {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'https://api.dev.ncle.dev/',
      'https://api.stg.ncle.dev/',
      'https://api.ncle.dev/',
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: environment.ENVIRONMENT_NAME,
  });
}

if (environment.production) {
  enableProdMode();
}

const appSetting: CometChat.AppSettings = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(environment.COMET_REGION)
  .autoEstablishSocketConnection(true)
  .build();

async function prepareMockApp() {
  if (environment.MOCK_ENABLED) {
    const { worker } = await import('./mocks/browser');
    return worker.start();
  }

  return Promise.resolve();
}

function initRbac(rbacService: RoleBaseAccessControlService) {
  return () => rbacService.fetchPermission();
}

export function preloadTranslation(transloco: TranslocoService) {
  return function () {
    return firstValueFrom(transloco.load(transloco.getActiveLang()));
  };
}

// TODO: check if still needed
export function preloadStudent(
  auth: AuthService,
  studentsService: StudentsService,
  studentSelectionScopeService: StudentSelectionScopeService,
) {
  return function () {
    if (auth.isUserLoggedIn() && auth.user()?.type === 'STUDENT') {
      return firstValueFrom(
        studentsService.getStudent(auth.user()!?.userTypeId).pipe(
          map((res) => {
            const mappedStudent = {
              ...res,
              school: {
                ...res.school,
                class: {
                  id: res?.classes?.[0].id,
                  name: res?.classes?.[0].name,
                },
                level: {
                  id: res.levels.id,
                  name: res.levels.name,
                },
              },
            };
            studentSelectionScopeService.updateStudentSelectionScope([
              mappedStudent,
            ]);
            studentSelectionScopeService.updateSelectedStudent(res.id);
            return res;
          }),
        ),
      );
    } else return of(null);
  };
}
// TODO: check if still needed
export function preloadGuardianStudents(
  auth: AuthService,
  studentSelectionScopeService: StudentSelectionScopeService,
  schoolStructureScopeService: SchoolStructureScopeService,
  guardianService: GuardianService,
  academicYearsScopeService: AcademicYearsScopeService,
) {
  return function () {
    if (auth.isUserLoggedIn() && auth.user()?.type === 'GUARDIAN') {
      return firstValueFrom(
        guardianService.getGuardian(auth.user()?.userTypeId!).pipe(
          map((g) => {
            if (g?.students?.length) {
              studentSelectionScopeService.updateStudentSelectionScope(
                g.students,
              );
              studentSelectionScopeService.updateSelectedStudent(
                g.students[0].id,
              );
              const firstStudentWithSchoolYear = g.students.find(
                (s) => s.school?.id && s.academicYear?.id,
              );
              if (firstStudentWithSchoolYear) {
                schoolStructureScopeService.updateSelectedStructure({
                  id: firstStudentWithSchoolYear.school.id,
                  type: 'school',
                } as sideMenuSchoolStructureItem);

                academicYearsScopeService.updateSelectedAcademicYear({
                  id: firstStudentWithSchoolYear.academicYear.id,
                  name: firstStudentWithSchoolYear.academicYear.name,
                } as AcademicYearItem);
              }
            }
            return g.students;
          }),
        ),
      );
    } else return of(null);
  };
}

CometChat.init(environment.COMET_APP_ID, appSetting)!
  .then((initialized) => {
    console.log('Initialization completed successfully', initialized);
    prepareMockApp()
      .then(() => {
        bootstrapApplication(AppComponent, {
          providers: [
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            provideIonicAngular({
              platform: {
                desktop: (win) => {
                  const isMobile =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      win.navigator.userAgent,
                    );
                  const isIpadOS =
                    /Macintosh/.test(win.navigator.userAgent) &&
                    'ontouchend' in document;
                  return !isMobile && !isIpadOS;
                },
              },
            }),
            provideRouter(routes, withComponentInputBinding()),
            provideHttpClient(
              withInterceptors([
                // Note: sequence of interceptors is important
                // 1. refresh token api call (RefreshTokenInterceptor)
                // 2. set token to storage (AuthTokenInterceptor)
                // 3. get token from storage and set to header (AuthHeadersInterceptor)
                // 4. Other & language middleware (LanguageMiddlewareInterceptor)
                RefreshTokenInterceptor,
                AuthTokenInterceptor,
                AuthHeadersInterceptor,
                LanguageMiddlewareInterceptor,
              ]),
            ),
            provideTransloco({
              config: {
                availableLangs: ['en', 'ar'],
                defaultLang: !isDevMode() ? 'ar' : 'en',
                prodMode: !isDevMode(),
              },
              loader: TranslocoHttpLoader,
            }),
            {
              provide: APP_INITIALIZER,
              multi: true,
              deps: [TranslocoService],
              useFactory: preloadTranslation,
            },
            {
              provide: APP_INITIALIZER,
              multi: true,
              deps: [
                AuthService,
                StudentsService,
                StudentSelectionScopeService,
              ],
              useFactory: preloadStudent,
            },
            {
              provide: APP_INITIALIZER,
              multi: true,
              deps: [
                AuthService,
                StudentSelectionScopeService,
                SchoolStructureScopeService,
                GuardianService,
                AcademicYearsScopeService,
              ],
              useFactory: preloadGuardianStudents,
            },
            importProvidersFrom(
              ReactiveFormsModule,
              BrowserAnimationsModule,
              TuiRootModule,
              TuiErrorModule,
            ),
            importProvidersFrom(BreadcrumbModule),
            {
              provide: APP_INITIALIZER,
              useFactory: initRbac,
              multi: true,
              deps: [RoleBaseAccessControlService],
            },
            provideAnimations(),
            provideToastr({
              timeOut: 3000,
              toastComponent: HesToastrComponent,
              closeButton: true,
              positionClass: 'toast-bottom-right',
            }),
            provideNgIconLoader((name) => {
              const http = inject(HttpClient);
              return http.get(`/assets/icons/${name}.svg`, {
                responseType: 'text',
              });
            }),
            // TUI Injection tokens configuration
            {
              provide: TUI_DATE_FORMAT,
              useFactory: (platform: Platform) =>
                platform.isRTL ? 'YMD' : 'DMY',
              deps: [Platform],
            },
            {
              provide: TUI_CANCEL_WORD,
              useFactory: (transloco: TranslocoService) =>
                of(transloco.translate('global.cancel.btn')),
              deps: [TranslocoService],
            },
            {
              provide: TUI_DONE_WORD,
              useFactory: (transloco: TranslocoService) =>
                of(transloco.translate('global.ok.btn')),
              deps: [TranslocoService],
            },
            tuiInputTimeOptionsProvider({
              mode: 'HH:MM',
              maxValues: { HH: 11, MM: 59, SS: 59, MS: 999 },
            }),
          ],
        });
      })
      .catch((err) => console.error(err));
  })
  .catch((error) => {
    console.log('Initialization failed with error:', error);
  });
